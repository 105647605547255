/* eslint-disable */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { ConfigContext } from "./ConfigProvider";
import { DomainContext } from "./DomainProvider";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Drawer from "../components/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDown from "../components/DropDown";
import CheckBox from "../components/CheckBox";
import Input from "../components/Input";

export const NewCompanyContext = React.createContext({
    orgnr: '',
    name: '',
    country: '',
    address: '',
    zipcode: '',
    city: '',
    image: null,
    users: [],
    existingCompany: null,
    useExistingCompany: false,
    getBrreg: () => {},
    loadExistingCompany: () => {},
    formik: null
})

export const NewCompanyProvider = ({children}) => {

    const { apiCall, accessToken, domain } = useContext(AuthContext)
    const { notify, apiUrl } = useContext(ConfigContext)
    const { reloadDomainCompanies } = useContext(DomainContext)

    const navigate = useNavigate()

    const [existingCompany, setExistingCompany] = useState(null)
    const [useExistingCompany, setUseExistingCompany] = useState(false)
    
    const [orgnr, setOrgnr] = useState('')
    const [users, setUsers] = useState([])

    const [templateTasks, setTemplateTasks] = useState(null)
    const [templateForms, setTemplateForms] = useState(null)
    const [templateDocuments, setTemplateDocuments] = useState(null)
    const [templateTypes, setTemplateTypes] = useState(null)
    const [templateTags, setTemplateTags] = useState(null)

    const [permissionTemplates, setPermissionTemplates] = useState([])

    const [userDrawerOpen, setUserDrawerOpen] = useState(false)



    

    useEffect(() => {
        getTemplateTasks()
        getTemplateDocuments()
        getTemplateForms()
        getTemplateSettings()
        getPermissionTemplates()
    }, [])


    const getTemplateTasks = async () => {
        const { data } = await apiCall({action: 'get-domain-template-tasks'});
        
        if(data.status === 1){
            
            setTemplateTasks(data.data)
        }
    }

    const getTemplateDocuments = async () => {
        const { data } = await apiCall({action: 'get-domain-template-documents'});
        
        if(data.status === 1){
            setTemplateDocuments(data.data)
        }
    }

    const getTemplateForms = async () => {
        const { data } = await apiCall({action: 'get-domain-template-forms'});

        if(data.status === 1){
            setTemplateForms(data.data)
        }
    }

    const getTemplateSettings = async () => {
        const { data } = await apiCall({action: 'get-domain-template-settings'});
        let types = []
        let tags = []
        if(data.status === 1){
            data.data.map((item) => {
                if(item.type === 1){
                    tags.push(item)
                }else{
                    types.push(item)
                }
                
            })
            setTemplateTags(tags)
            setTemplateTypes(types)
        }
    }

    const uploadFile = async (file) => {
        const formData = new FormData();

        formData.append('accessToken', accessToken)
        formData.append('action', 'upload-single-attachment')
        formData.append('files[]', file)

        const { data } = await axios({
            method: 'post',
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })

        if(data.status !== 1){
            notify(data.status, data.title, data.message)
            return null
        }

        return data.data

    }

    const getPermissionTemplates = async () => {
        const { data } = await apiCall({action: 'get-company-permission-templates'});
        if(data.status === 1){
            setPermissionTemplates(data.data)
        }
        
    }

    const save = async (data) => {
        
        const logoData = data.logo instanceof File ? await uploadFile(data.logo) : null


        const status = await apiCall({
            action: 'create-company', 
            orgnr: data.orgnr,
            name: data.name,
            logo: logoData,
            country: data.country ? data.country.value : null,
            address: data.address,
            zip: data.zip,
            city: data.city,
            type: data.type ? data.type.value : null,
            tags: data.tags,
            tasks: data.tasks,
            forms: data.forms,
            documents: data.documents,
            users: data.users,
            admins: data.admins
        })
        
        notify(status.data.status, status.data.title, status.data.message)

        if(status.data.status === 1){
            reloadDomainCompanies()
            if(status.data.data.hash){
                navigate(`/${domain.hash}/${status.data.data.hash}/oversikt`)
            }
        }
    }

    const loadExistingCompany = async () => {
        if(!existingCompany){
            const { data } = await apiCall({
                action: 'get-company-by-org',
                orgnr: formik.values.orgnr
            })

            if(data.status === 1){
                console.log(data.data)
                
                setExistingCompany(data.data)
            }
        }
    }

    useEffect(() => {
        if(useExistingCompany && existingCompany){
            formik.setFieldValue('name', existingCompany.name)
            formik.setFieldValue('country', existingCompany.country)
            formik.setFieldValue('address', existingCompany.payload.address)
            formik.setFieldValue('zip', existingCompany.payload.zip)
            formik.setFieldValue('city', existingCompany.payload.city)
        }
    }, [existingCompany, useExistingCompany])

    let schema = yup.object().shape({
        orgnr: yup.string().matches(/^[0-9]{9}$/, 'Org.nr. må være 9 siffer').required("Org.nr. er påkrevd"),
        name: yup.string().required("Bedriftsnavn er påkrevd"),
        country: yup.object().typeError("Land er påkrevd").required(),
        address: yup.string().required("Adresse er påkrevd"),
        zip: yup.string().matches(/^[0-9]{4}$/, 'Postnummer må være 4 siffer').required("Postnummer er påkrevd"),
        city: yup.string().required("Poststed er påkrevd"),
    });

    const formik = useFormik({
        initialValues: {
            orgnr: '',
            name: '',
            country: null,
            address: '',
            zip: '',
            city: '',
            logo: null,
            admins: [],
            type: null,
            tags: [],
            tasks: [],
            forms: [],
            documents: [],
            users: [],
        },
        validationSchema: schema,
        onSubmit: async (values) => {      
            console.log('Submit')      
            save(values)
        },
    });

    useEffect(() => {
        if(formik.values.orgnr.length === 9){
            loadExistingCompany()
        }else{
            setExistingCompany(null)
            setUseExistingCompany(false)
        }
    }, [formik])

    return <NewCompanyContext.Provider value={{

        templateTasks,
        templateForms,
        templateDocuments,
        templateTags,
        templateTypes,
        permissionTemplates,
        existingCompany, setExistingCompany,
        useExistingCompany, setUseExistingCompany,
        formik,
        save,
        setUserDrawerOpen,
        getBrreg: () => {
            const trimmed = orgnr.replace(/\s+/g, '');
            axios.get('//data.brreg.no/enhetsregisteret/api/enheter/' + trimmed, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then((r) => {
                console.log(r)
            })
        },
        loadExistingCompany
    }}>{
        !templateTasks || !templateDocuments || !templateTags || !templateTypes ? 
            <h1>Laster</h1> : 
            (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        {children}
                    </form>
                    <Drawer
                        open={userDrawerOpen}
                        setOpen={setUserDrawerOpen}
                    >
                        <AddUser setUserDrawerOpen={setUserDrawerOpen} />
                    </Drawer> 
                </>
            )
        }</NewCompanyContext.Provider>
}


function AddUser(){

    const { permissionTemplates, setUserDrawerOpen, formik } = useContext(NewCompanyContext)

    let userSchema = yup.object().shape({
        firstname: yup.string().required("Fornavn er påkrevd"),
        lastname: yup.string().required("Etternavn er påkrevd"),
        phone: yup.string().matches(/^[0-9]{8}$/, 'Telefonnummer må være 8 siffer').required("Telefonnummer er påkrevd"),
        role: yup.string().required("Stillingstittel / rolle er påkrevd"),
        email: yup.string().email("Må være en gyldig e-postadresse").required("e-postadresse er påkrevd"),
        permissionTemplate: yup.object().typeError("Rolle er påkrevd").nullable(false).required('Rolle er påkrevd'),
        active: yup.boolean()
    });

    const userFormik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            phone: '',
            role: '',
            email: '',
            permissionTemplate: null,
            active: false
        },
        validationSchema: userSchema,
        onSubmit: async (values) => {            
            formik.setFieldValue('users', [...formik.values.users, {
                firstname: values.firstname,
                lastname: values.lastname,
                phone: values.phone,
                role: values.role,
                email: values.email,
                permissionTemplate: values.permissionTemplate,
                active: values.active
            }])
            setUserDrawerOpen(false)
        },
    });

    

    return (
        <div className="py-3 d-flex flex-column h-100">
            <div className="col">
                <div className="row justify-content-between mb-4">
                    <div className="col-auto pointer" onClick={() => setUserDrawerOpen(false)}>
                        <div className="row">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['far', 'arrow-left']} />
                            </div>
                            <div className="col-auto">
                                <h6 className="bold">Opprett ny kunde</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto pointer" onClick={() => setUserDrawerOpen(false)}>
                        <FontAwesomeIcon icon={['far', 'xmark']} />
                    </div>
                </div>
                <h3 className="bold">Legg til bruker</h3>
                <hr className="mb-4" style={{opacity: '.1'}} />
            
                <form onSubmit={userFormik.handleSubmit}>
                    <div className="row pb-3 mb-3 border-bottom border-dashed">
                        <div className="col-md-6 mb-3">
                            <Input
                                name="firstname"
                                label="Fornavn"
                                type="text"
                                value={userFormik.values.firstname}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.firstname && Boolean(userFormik.errors.firstname)}
                                touched={userFormik.touched.firstname}
                                helperText={userFormik.touched.firstname && userFormik.errors.firstname}
                                placeholder={"Medlemmets fornavn"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="lastname"
                                label="Etternavn"
                                type="text"
                                value={userFormik.values.lastname}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.lastname && Boolean(userFormik.errors.lastname)}
                                touched={userFormik.touched.lastname}
                                helperText={userFormik.touched.lastname && userFormik.errors.lastname}
                                placeholder={"Medlemmets etternavn"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="role"
                                label="Stillingstittel / rolle"
                                type="text"
                                value={userFormik.values.role}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.role && Boolean(userFormik.errors.role)}
                                touched={userFormik.touched.role}
                                helperText={userFormik.touched.role && userFormik.errors.role}
                                placeholder={"Stillingstittel / rolle"}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="phone"
                                label="Telefonnummer"
                                type="text"
                                value={userFormik.values.phone}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.phone && Boolean(userFormik.errors.phone)}
                                touched={userFormik.touched.phone}
                                helperText={userFormik.touched.phone && userFormik.errors.phone}
                                placeholder={"Fyll inn telefonnummer (8 siffer)"}
                            />  
                        </div>
                        <div className="col-md-6 mb-3">
                            <Input
                                name="email"
                                label="e-postadresse"
                                type="text"
                                value={userFormik.values.email}
                                onChange={userFormik.handleChange}
                                error={userFormik.touched.email && Boolean(userFormik.errors.email)}
                                touched={userFormik.touched.email}
                                helperText={userFormik.touched.email && userFormik.errors.email}
                                placeholder={"Fyll inn medlemmets e-postadresse"}
                            /> 
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Brukertilgang</label>
                                <DropDown
                                    name="permissionTemplate"
                                    error={userFormik.touched.permissionTemplate && Boolean(userFormik.errors.permissionTemplate)}
                                    options={permissionTemplates.map(item => {
                                        return {label: item.name, value: item.id}
                                    })}
                                    value={userFormik.values.permissionTemplate}
                                    placeholder="Velg en rolle"
                                    onChange={(e) => userFormik.setFieldValue("permissionTemplate", e)}
                                    touched={userFormik.touched.permissionTemplate}
                                    helperText={userFormik.touched.permissionTemplate && userFormik.errors.permissionTemplate}

                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Send tilgang med en gang</label>
                                <CheckBox 
                                    name="active"
                                    label="Aktiver medlem ved opprettelse"
                                    value={userFormik.values.active}
                                    classes="checkbox-success"
                                    onChange={(e) => userFormik.setFieldValue("active", e)}
                                    description="Aktivering av medlemmet gir umiddelbar invitasjon og tilgang til systemet"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <button type="button" className="btn btn-border btn-block w-100" onClick={() => setUserDrawerOpen(false)}>Avbryt og lukk</button>
                        </div>
                        <div className="col-lg-6">
                            <button className="btn btn-success w-100" type="submit">Legg til bruker</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}