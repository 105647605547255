/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import UserPhoto from "./UserPhoto";


export const styleText = (text) => {
    let finalText = ''
    
    if(!text) return
    text.forEach((block) => {
        let textContent = block.text;
        finalText = textContent
        block.entityRanges.forEach((range) => {
                
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            finalText = finalText.replace(temp, `<a href="#" class="link">${temp}</a>`)
        })

        block.inlineStyleRanges.forEach((range) => {
                
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            switch(range.style){
                case 'ITALIC':
                    finalText = finalText.replace(temp, `<i>${temp}</i>`)
                    break;
                case 'BOLD':
                    finalText = finalText.replace(temp, `<b>${temp}</b>`)
                    break;
                case 'UNDERLINE':
                    finalText = finalText.replace(temp, `<u>${temp}</u>`)
                    break;
            }
        })

    })

    return finalText
}

export const parseLogText = (messageRaw) => {

    const { domain, company } = useContext(AuthContext)
    let finalText = null
    const data = []
    let message = ''
    if(!messageRaw) return ''
    
    if(typeof messageRaw === 'object'){
        message = messageRaw
    }else{
        message = JSON.parse(messageRaw)
    }

    if(!message) return ''

    
    message.blocks.forEach((block, key) => {
        let originalText = block.text.split(/[\s,]+/)
        let textContent = block.text

        block.entityRanges.forEach((range) => {
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            const index = originalText.findIndex((text) => text === temp)

            switch(message.entityMap[range.key].data.mention.type){
                case 'SagaNewTask':
                    originalText[index] = <Link to={`/${domain.hash}${company ? `/${company.hash}` : ''}/oppgaver/${message.entityMap[range.key].data.mention.id}`} className="text-primary">{message.entityMap[range.key].data.mention.name}</Link>
                    break
                case 'SagaDocument':
                    originalText[index] = <Link to={`/${domain.hash}${company ? `/${company.hash}` : ''}/dokumenter/${message.entityMap[range.key].data.mention.alias}`} className="text-primary">{message.entityMap[range.key].data.mention.name}</Link>
                    break;
                case 'SagaChannel':
                    originalText[index] = <Link to={`/${domain.hash}${company ? `/${company.hash}` : ''}/kanaler/${message.entityMap[range.key].data.mention.alias}`} className="text-primary">{message.entityMap[range.key].data.mention.name}</Link>
                    break;
                default:
                    originalText[index] = <span className="text-primary">{message.entityMap[range.key].data.mention.name}</span>
            }
            
        })

        data.push(originalText.map(t => <span>{t}</span>)
        .reduce((accu, elem) => {
            return accu === null ? [elem] : [...accu, ' ', elem]
        }, null))

    })
    return <div>{data}</div>
    return finalText
}

export const styleBlockedText = (messageRaw) => {
    let finalText = ''
    let message = ''


    if(!messageRaw) return ''
    
    if(typeof messageRaw === 'object'){
        message = messageRaw
    }else{
        message = JSON.parse(messageRaw)
    }

    if(!message) return ''

    
    message.blocks.forEach((block, key) => {
        
        let textContent = linkify(block.text);

        finalText += textContent
        
        block.entityRanges.forEach((range) => {
            
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            
            if(message.entityMap[range.key].type === 'mention'){
                finalText = finalText.replace(temp, `<a href="#" class="link">${message.entityMap[range.key].data.mention.fullname}</a> `)
            }

            if(message.entityMap[range.key].type === '#mention'){
                finalText = finalText.replace(temp, `<a href="#" class="link">${message.entityMap[range.key].data.mention.title}</a>`)
            }
            
        })

        block.inlineStyleRanges.forEach((range) => {
                
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            switch(range.style){
                case 'ITALIC':
                    finalText = finalText.replace(temp, `<i>${temp}</i>`)
                    break;
                case 'BOLD':
                    finalText = finalText.replace(temp, `<b>${temp}</b>`)
                    break;
                case 'UNDERLINE':
                    finalText = finalText.replace(temp, `<u>${temp}</u>`)
                    break;
            }
        })

        

    })

    return finalText
}

function linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a class="text-primary" href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a class="text-primary" href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a class="text-primary" href="mailto:$1">$1</a>');

    return replacedText;
}

export const rteMarkup = (messageRaw) => {
    let finalText = ''
    let message = ''

    
    if(typeof messageRaw === 'object'){
        message = messageRaw
    }else{
        message = JSON.parse(messageRaw)
    }

    
    message.blocks.forEach((block, key) => {
        
        let textContent = block.text;
        let textClone = block.text.replace(/\n/g, "<br />");

        block.entityRanges.forEach((range) => {
            
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            
            if(message.entityMap[range.key].type === 'mention'){
                textClone = textClone.replace(temp, `<a href="#" class="link">${message.entityMap[range.key].data.mention.fullname}</a> `)
            }

            if(message.entityMap[range.key].type === 'LINK'){
                textClone = textClone.replace(temp, `<a href="${message.entityMap[range.key].data.url}" target="${message.entityMap[range.key].data.targetOption}" class="link">${temp}</a> `)
            }

            if(message.entityMap[range.key].type === '#mention'){
                textClone = textClone.replace(temp, `<a href="#" class="link">${message.entityMap[range.key].data.mention.title}</a>`)
            }

            if(message.entityMap[range.key].type === 'IMAGE'){
                textClone = textClone.replace(temp, `<img src="${message.entityMap[range.key].data.src}" />`)
            }
            
        })

        block.inlineStyleRanges.forEach((range) => {
                
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            switch(range.style){
                case 'ITALIC':
                    textClone = textClone.replace(temp, `<i>${temp}</i>`)
                    break;
                case 'BOLD':
                    textClone = textClone.replace(temp, `<b>${temp}</b>`)
                    break;
                case 'UNDERLINE':
                    textClone = textClone.replace(temp, `<u>${temp}</u>`)
                    break;
            }
        })

        switch(block.type){
            case 'header-one':
                finalText += `<h1>${textClone}</h1>`
                break;
            case 'header-two':
                finalText += `<h2>${textClone}</h2>`
                break;
            case 'header-three':
                finalText += `<h3>${textClone}</h3>`
                break;
            case 'header-four':
                finalText += `<h4>${textClone}</h4>`
                break;
            case 'header-five':
                finalText += `<h5>${textClone}</h5>`
                break;
            case 'unordered-list-item':
                
                finalText += !message.blocks[key - 1]?.type === 'unordered-list-item' ? '<ul>' : ''
                finalText += `<li>${textClone}</li>`
                finalText += !message.blocks[key + 1]?.type === 'unordered-list-item' ? '</ul>' : ''
               
                break
            case 'ordered-list-item': 
                          
                finalText += !message.blocks[key - 1]?.type === 'ordered-list-item' ? '<ol>' : ''
                finalText += `<li>${textClone}</li>`
                finalText += !message.blocks[key + 1]?.type === 'ordered-list-item' ? '</ol>' : ''
               
                break
            default:
                finalText += `<p>${textClone}</p>`
        }
    })

    return finalText
}

export const getDeadlineType = ({deadline}) => {
    if(!deadline) return 'Ingen'
    if(!deadline.repetition) return 'Ingen'
    switch(deadline.repetition){
        case 1:
            return 'Ingen'
        case 2:
            return 'Daglig'
        case 3:
            return 'Ukentlig'
        case 4:
            return 'Månedlig'
        case 5:
            return 'Kvartalsvis'
        case 6:
            return 'Årlig'
    }
}

/** PARAMS = task */
export const canDoDask = ({deadline, lastSubmission}) => {
    const now = moment()
    
    if(!deadline) return false
    
    const start = moment(deadline.startDate)
    const end = moment(deadline.endDate)
    const last = lastSubmission ? moment(lastSubmission) : null
    const startAfter = start.isAfter(now)

    /** 
     * Ingen repetisjon 
     * Kan alltid utføres
    */
    if(deadline.repetition === 1) return true
        
    
    /** 
     * Daglig repetisjon
     * Skal kunne utføres én gang om dagen
    */
    if(deadline.repetition === 2){
        if(startAfter){
            // Hvis startdato for oppgaven er etter i dag
            return false
        }

        const prev = moment().add(-1, 'day')
        const next = moment().add(1, 'day')

        if(last){
            // Er oppgaven utført mellom i går og i dag? Viser neste dag
            if(last.isBetween(prev, next)){
                return false
            }

        }
        return true
    }
    /** 
     * Ukentlig repetisjon
     * Skal kunne utføres én gang i uken
     */
    if(deadline.repetition === 3) {
        const weekDay = start.day()
        const nextWeekDay = moment().startOf('isoWeek').add(weekDay, 'week')
        const prevWeekDay = moment().startOf('isoWeek')

        if(last){
            // Hvis det finnes en oppgaveutførelse i inneværende uke
            if(last.isBetween(prevWeekDay, nextWeekDay)){
                return false
            }

        }
        return true
    }
    if(deadline.repetition === 4) {
        // Hvis startdato for oppgaven er etter i dag viser vi start-dato
        const dayOfMonth = start.date()
        const nextDate = moment().startOf('month').add((dayOfMonth - 1), 'days')

        if(now.isAfter(nextDate)){
            nextDate.add(1, 'month')
        }

        if(last && last.isBetween(moment(nextDate).subtract(1, 'month'), nextDate)){
            nextDate.add(1, 'month')
        }

        const prevDate = moment(nextDate).subtract(1, 'month');

        if(now.isBetween(prevDate, nextDate)){
            return true
        }

        return false
    }
    if(deadline.repetition === 5) return true
    if(deadline.repetition === 6) return true
}
export const getNextSubtaskDeadline = (arr) => {
    let lastDiff;
    let nearest;
    
    for (const date of arr) {
      const diff = Math.abs(moment(date, 'MMMM Do').diff(moment(), 'days'));
    
      if (lastDiff === undefined || lastDiff > diff) {
        lastDiff = diff;
        nearest = date;
      }
    }
    return nearest
}

function sortDeadlineArray(dates){
    const momentDates = dates.map((date) => moment(date))

    const sortedDates = momentDates.sort((a, b) => {
        return a.format('X') - b.format('X')
    })

    return sortedDates
}

export const getNextDeadline = ({deadline, subtasks, lastSubmission, submissions}) => {
    
    
    if(!deadline) return {
        text: 'Åpen oppgave',
        timestamp: 99999999999,
        available: true,
        formated: '',
        daysUntil: null,
        object: null,
        type: 'Ingen'
    }

    
    const now = moment()
    const start = moment(deadline.startDate)
    const end = moment(deadline.endDate)
    const last = lastSubmission ? moment(lastSubmission) : null
    const startAfter = start.isAfter(now)
    let available = true


    if(deadline.repetition === 1){
        //Hvis det er valgt flere datoer enn én
        if(deadline.dates){
            
            if(submissions){
                const sortedDates = sortDeadlineArray(deadline.dates)
                const date = sortedDates[submissions.length]

                if(date){
                    return {
                        text: `Neste frist: ${date.format('DD. MMM YY')}`,
                        timestamp: parseInt(date.format('X')),
                        available: true,
                        formated: '',
                        daysUntil: date.diff(now, 'days'),
                        object: date,
                        type: 'Intervall'
                    }
                }
            }
            
        }
        
        //Hvis det er en åpen oppgave
        if(!deadline.startDate){
            
            //Finnes det underoppgaver som har en frist vi heller skal vise
            if(subtasks && subtasks.length > 0){
                let temp = []
                subtasks.forEach((item) => {
                    
                    if(!item.archivedon){
                        const nextSubtaskDeadline = getNextDeadline({deadline: item.deadline, subtasks: null, lastSubmission: item.lastSubmission, submissions: item.submissions})
                        
                        if(nextSubtaskDeadline.available) temp.push(nextSubtaskDeadline.object)
                        
                    }
                })

                
                const nexxxt = temp.length > 1 ? getNextSubtaskDeadline(temp) : temp
                
                
                if(nexxxt && nexxxt._isValid){
                    return {
                        text: `Frist: ${nexxxt.format('DD. MMM YY')}`,
                        timestamp: parseInt(nexxxt.format('X')),
                        available: true,
                        formated: '',
                        daysUntil: nexxxt.diff(now, 'days'),
                        object: nexxxt,
                        type: 'Ingen'
                    }
                }
            }

            return {
                text: 'Åpen oppgave',
                timestamp: 99999999999,
                available: true,
                formated: '',
                daysUntil: null,
                object: null,
                type: 'Ingen'
            }
        }      
        
        const startAfter = start.isAfter(now)

        if(startAfter){
            return {
                text: `Frist: ${start.format('DD. MMM YY')}`,
                timestamp: parseInt(start.format('X')),
                available: true,
                formated: '',
                daysUntil: start.diff(now, 'days'),
                object: start,
                type: 'Ingen'
            }
        }

        const prev = moment().subtract(1, 'day')
        const next = moment().add(1, 'day')

        if(last){
            // Er oppgaven utført mellom i går og i dag? Viser neste dag
            if(last.isBetween(prev, next)){
                
                return {
                    text: `Frist: ${next.format('DD. MMM YY')} 2`,
                    timestamp: parseInt(next.format('X')),
                    available: true,
                    formated: '',
                    daysUntil: next.diff(now, 'days'),
                    object: next,
                    type: 'Ingen'
                }
            }

        }

        if(!start._isValid) return {
            text: 'Åpen oppgave',
            timestamp: 99999999999,
            available: true,
            formated: '',
            daysUntil: null,
            object: null,
            type: 'Ingen'
        }

        return {
            text: `Frist: ${start.format('DD. MMM YY')}`,
            timestamp: parseInt(start.format('X')),
            available: true,
            formated: '',
            daysUntil: start.diff(now, 'days'),
            object: null,
            type: 'Ingen'
        }

    }

    if(deadline.repetition === 2) {
        
        
        const next = moment(start).subtract(1, 'day')

        let found = true
        do {
            next.add(1, 'day')
            if(!submissions) break;
            let result = submissions.filter((submission) => {
                const submissionDate = moment(submission.archivedon)
                
                return next.isSame(submissionDate, 'day')
            })

            found = result.length
        } while (found);

        
        return {
            text: `Neste frist: ${next.format('DD. MMM YY')}`,
            timestamp: parseInt(next.format('X')),
            available: next.clone().startOf('day').diff(now.startOf('day'), 'days') < 1,
            formated: '',
            daysUntil: next.clone().startOf('day').diff(now.startOf('day'), 'days'),
            object: next,
            type: 'Daglig'
        }
    }

    if(deadline.repetition === 3){
        
        const next = moment(start).subtract(1, 'week')

        let found = true
        do {
            next.add(1, 'week')
            if(!submissions) break;
            let result = submissions.filter((submission) => {
                const submissionDate = moment(submission.archivedon)
                
                return next.isSame(submissionDate, 'week')
            })

            found = result.length
        } while (found);

        return {
            text: `Neste frist: ${next.format('DD. MMM YY')}`,
            timestamp: parseInt(next.format('X')),
            available: next.clone().startOf('week').diff(now.startOf('week'), 'days') < 1,
            formated: '',
            daysUntil: next.clone().startOf('week').diff(now.startOf('week'), 'days'),
            object: next,
            type: 'Ukentlig'
        }
    }

    if(deadline.repetition === 4){
        // Hvis startdato for oppgaven er etter i dag viser vi start-dato

        const next = moment(start).subtract(1, 'month')

        let found = true
        do {
            next.add(1, 'month')
            if(!submissions) break;
            let result = submissions.filter((submission) => {
                const submissionDate = moment(submission.archivedon)
                
                return next.isSame(submissionDate, 'month')
            })

            found = result.length
        } while (found);


        return {
            text: `Neste frist: ${next.format('DD. MMM YY')}`,
            timestamp: parseInt(next.format('X')),
            available: next.clone().startOf('month').diff(now.clone().startOf('month'), 'days') <= 1,
            formated: next.format('DD. MMM YY'),
            daysUntil: next.diff(now, 'days'),
            object: next,
            type: 'Månedlig'
        }


        if(startAfter){
            
            if(last && last.isBetween(moment(deadline.startDate).subtract(1, 'month'), moment(deadline.startDate))){
                
                return {
                    text: `Neste frist: ${moment(deadline.startDate).format('DD. MMM YY')}`,
                    timestamp: parseInt(moment(deadline.startDate).format('X')),
                    available: false,
                    formated: '',
                    daysUntil: moment(deadline.startDate).diff(now, 'days'),
                    object: moment(deadline.startDate),
                    type: 'Månedlig'
                }
            }
            return {
                text: `Neste frist: ${start.format('DD. MMM YY')}`,
                timestamp: parseInt(start.format('X')),
                available: true,
                formated: '',
                daysUntil: start.diff(now, 'days'),
                object: start,
                type: 'Månedlig'
            }
        }

        const dayOfMonth = start.date()

        const nextDate = moment().startOf('month').add((dayOfMonth - 1), 'days')

        if(now.isAfter(nextDate)){
            nextDate.add(1, 'month')
        }
        
        if(last && last.isBetween(moment(nextDate).subtract(1, 'month'), nextDate)){
            nextDate.add(1, 'month')
        }
                
        //return `Neste frist: ${nextDate.format('DD. MMM YY')}`
        return {
            text: `Neste frist: ${nextDate.format('DD. MMM YY')}`,
            timestamp: parseInt(nextDate.format('X')),
            available: true,
            formated: nextDate.format('DD. MMM YY'),
            daysUntil: nextDate.diff(now, 'days'),
            object: nextDate,
            type: 'Månedlig'
        }
        

    }
    if(deadline.repetition === 5){
        const next = moment(start).subtract(1, 'Q')
        

        let found = true
        do {
            next.add(1, 'Q')
            if(!submissions) break;
            let result = submissions.filter((submission) => {
                const submissionDate = moment(submission.archivedon)
                return next.isSame(submissionDate, 'Q')
            })

            found = result.length
        } while (found);

        
        return {
            text: `Neste frist: ${next.format('DD. MMM YY')}`,
            timestamp: parseInt(next.format('X')),
            available: next.clone().startOf('Q').diff(now.startOf('Q'), 'days') < 1,
            formated: next.format('DD. MMM YY'),
            daysUntil: next.diff(now, 'days'),
            object: next,
            type: 'Kvartalsvis'
        }
    }
    if(deadline.repetition === 6) {
        // Finner nærmeste first
        const next = moment(start).subtract(1, 'year')
        

        let found = true
        do {
            next.add(1, 'year')
            if(!submissions) break;
            let result = submissions.filter((submission) => {
                const submissionDate = moment(submission.archivedon)
                return next.isSame(submissionDate, 'year')
            })

            found = result.length
        } while (found);

        return {
            text: `Neste frist: ${next.format('DD. MMM YY')}`,
            timestamp: parseInt(next.format('X')),
            available: next.clone().startOf('year').diff(now.startOf('year'), 'days') < 1,
            formated: next.format('DD. MMM YY'),
            daysUntil: next.diff(now, 'days'),
            object: next,
            type: 'Årlig'
        }


        let date = moment().startOf('year')
        date.add(start.month(), 'months')
        date.add(start.date() - 1, 'days')
        
        // Sjekker om frist er før nåværende dato
        if(now.isAfter(date)){
            date.add(1, 'year')
        }

        if(last && last.isBetween(moment(date).subtract(1, 'year'), date)){
            date.add(1, 'year')
        }

        //return `Neste frist: ${date.format('DD. MMM YY')}`
        return {
            text: `Neste frist: ${date.format('DD. MMM YY')}`,
            timestamp: parseInt(date.format('X')),
            available: true,
            formated: '',
            daysUntil: date.diff(now, 'days'),
            object: date,
            type: 'Årlig'
        }
    }

    return {
        text: `Åpen oppgave`,
        timestamp: 9999999999999,
        available: true,
        formated: '',
        daysUntil: null,
        object: null,
        type: 'Ingen'
    }
}

export const getTaskRepetitionType = (interval) => {

    if(!interval){
        return {
            type: 'Feil',
            description: 'Feil'
        }
    }
    
    if(interval.repetition === 1){
        return {
            type: 'Ingen',
            description: interval.startDate ? moment(interval.startDate).format('DD. MMM YY') : 'Åpen'
        }
    }

    if(interval.repetition === 2){
        return {
            type: 'Daglig',
            description: `Fra ${interval.startDate ? moment(interval.startDate).format('DD. MMM YY') : '(ingen dato valgt)'}`
        }
    }

    if(interval.repetition === 3){
        return {
            type: 'Ukentlig',
            description: moment().weekday(interval.weekDay.value - 1).format('dddd')
        }
    }

    if(interval.repetition === 4){
        return {
            type: 'Månedlig',
            description: `${moment().date(interval.monthDay.value).format('DD.')} hver måned`
        }
    }

    if(interval.repetition === 5){
        return {
            type: 'Kvartalsvis',
            description: 'Siste dag i kvartalet'
        }
    }

    if(interval.repetition === 6){
        return {
            type: 'Årlig',
            description: `${moment().date(interval.monthDay.value).format('DD.')} ${moment().month(interval.month.value - 1).format('MMMM')} en gang i året`
        }
    }

    return {
        type: 'Ikke valgt',
        description: ``
    }
    
}

const getRepetitonName = (repetition) => {
    switch(interval.repetition){
        case 1: return 'Ingen'
        case 2: return 'Daglig'
        case 3: return 'Ukentlig'
        case 4: return 'Månedlig'
        case 5: return 'Kvartalsvis'
        case 6: return 'Årlig'
    }
}

export const formatDate = (data, format, prefix = false) => {
    const now = moment()
    const date = moment(data)
    const diff = now.diff(date, 'days')

    if(diff === 0) return 'i dag'
    if(diff === 1) return  `${(prefix ? `for ` : null)} ${diff} dag siden`
    if(diff < 7) return `${(prefix ? `for ` : null)} ${diff} dager siden`

    if(format === 'diff') return `${(prefix ? `for ` : null)} ${diff} dager siden`

    if(!format) return date.format('DD.MM.YYYY')
    return date.format(format)
}

export const Entry = ({ mention, isFocused, id, onMouseUp, onMouseDown, onMouseEnter }) => {
    
    const entryRef = useRef(null);
    let className = "mention-text";
  
    if (isFocused) {
      className += " mention-focused";
    }
  
    useEffect(() => {
      if (isFocused) {
        if ("scrollIntoViewIfNeeded" in document.body) {
          entryRef.current.scrollIntoViewIfNeeded(false);
        } else {
          entryRef.current.scrollIntoView(false);
        }
      }
    }, [isFocused]);
  
    return (
      <div
        ref={entryRef}
        className="row p-2"
        role="option"
        aria-selected={isFocused ? "true" : "false"}
        id={id}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseDown={onMouseDown}
      >
          <div className="col-auto">
              <UserPhoto photo={mention.photo} name={mention.fullname} size={[30, 30, 30]} />
          </div>
          <div className="col">
            {mention.fullname}
        </div>
      </div>
    );
}

export const HashtagEntry = ({ mention, isFocused, id, onMouseUp, onMouseDown, onMouseEnter }) => {
    
    const entryRef = useRef(null);
    let className = "mention-text";
  
    if (isFocused) {
      className += " hover-primary";
    }

    const Icon = () => {
        let icon = 'file'

        switch(mention.type){
            case 'SagaNewTask': icon = 'chart-simple'; break;
            case 'SagaDocument': icon = 'file-lines'; break;
            case 'SagaTemplateTask': icon = 'pen-to-square'; break
        }

        return (
            <div style={{height: 40, width: 40, background: 'var(--light-grey)', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesomeIcon className="text-primary" size="1x" icon={['fas', icon]} />
            </div>
        )
    }
    
    const Type = () => {
        switch(mention.type){
            case 'SagaNewTask': return 'Oppgave'
            case 'SagaDocument': return 'Dokumentområde'
            case 'SagaTemplateTask': return 'Oppgavemal'
            default: return ''
        }
    }
    useEffect(() => {
      if (isFocused) {
        if ("scrollIntoViewIfNeeded" in document.body) {
          entryRef.current.scrollIntoViewIfNeeded(false);
        } else {
          entryRef.current.scrollIntoView(false);
        }
      }
    }, [isFocused]);
  
    return (
      <div
        ref={entryRef}
        className="row p-2"
        role="option"
        aria-selected={isFocused ? "true" : "false"}
        id={id}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseDown={onMouseDown}
      >
          <div className="col-auto">
              <Icon />
          </div>
          <div className="col">
            <span className="bold">{mention.title}</span>
            <div className="row gx-1">
                <div className="col-auto">
                    <small><Type /></small>
                </div>
                {
                    mention.company ? 
                        <div className="col-auto">for {mention.company?.name}</div>
                    : null
                }
                
            </div>
            
            </div>
      </div>
    );
}

export function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          const popper = document.getElementsByClassName('popper')
          const flatpickr = document.getElementsByClassName('flatpickr-calendar')
        
          let bool = false
            
          if (!ref.current || ref.current.contains(event.target)) {  
            return;
          }

          if(popper){
            for(const p of popper){
                if(p.contains(event.target)) bool = true
            }
          }

          if(flatpickr){
            for (const f of flatpickr) {
                if(f.contains(event.target)) bool = true   
            }
          }

          if(bool) return

          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
}

export function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
}

export function debounceAsync(func, waitMs) {
    let timeout = null;
  
    return (...args) => {
      clearTimeout(timeout);
      return new Promise((resolve) => {
        timeout = setTimeout(() => resolve(func(...args)), waitMs);
      });
    };
}
