/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios'
import { ConfigContext } from "./ConfigProvider";
import Login from "../pages/Login";
import { useNavigate, useParams } from "react-router-dom";
import FullscreenLoading from "../components/FullscreenLoading";
import UserPhoto from "../components/UserPhoto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const AuthContext = React.createContext({
    accessToken: null,
    mustRelog: false,
    user: null,
    consultant: false,
    teamleader: false,
    admin: false,
    teams: null,
    notificationCenter: false,
    messageCenter: false,
    unreadMessages: [],
    notifications: [],
    contextNotifications: [],
    login: () => {},
    logout: () => {},
    getLoginState: () => {},
    apiCall: () => {},
    toggleNotificationCenter: () => {},
    getUnreadMessages: () => {},
    getNotifications: () => {},
    changeDomain: () => {},
    changeCompany: () => {}
})

export const AuthProvider = ({children}) => {
    const { notify, apiUrl, baseUrl } = useContext(ConfigContext)
    const [user, setUser] = useState(null)
    const [accessToken, setAccessToken] = useState(localStorage.getItem('tudu.access_token'))
    const [consultant, setConsultant] = useState(false)
    const [teamleader, setTeamleader] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [teams, setTeams] = useState(null)
    const [notificationCenter, setNotificationCenter] = useState(false)
    const [messageCenter, setMessageCenter] = useState(false)
    const [mustRelog, setMustRelog] = useState(false)
    const [unreadMessages, setUnreadMessages] = useState([])

    const [notifications, setNotifications] = useState([])
    const [messages, setMessages] = useState([])
    const [contextNotifications, setContextNotifications] = useState([])
    const [contextMessages, setContextMessages] = useState([])

    const [contexts, setContexts] = useState(null)
    const [paths, setPaths] = useState(null)

    const [domain, setDomain] = useState(null)
    const [company, setCompany] = useState(null)

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if(accessToken){
            changeContext()
            loadNotifications()
        }
        
    }, [params])

    useEffect(() => {
        if(accessToken){
            getAvailablePaths()
        }
        
    }, [accessToken])

    useEffect(() => {
        if(teams && teams.length){
            teams.forEach((team) => {
                if(team.role === 'Leader') setTeamleader(true)
            })
        }
    }, [teams])

    useEffect(() => {
        if(accessToken){
            loadUnreadMessages()
            loadNotifications()
        }
    }, [])

    const reloadUser = async () => {
        const { data } = await doApiCall({
            action: 'reload-user'
        })

        if(data.status === 1){
            setUser(data.data)
        }
    }

    const loadCompanyByUrl = async () => {
        const status = await doApiCall({
            action: 'api-get-company-by-alias',
            alias: params.company
        })
        
        if(status && status.data){
            setCompany(status.data.data)
        }
    }

    const loadDomain = async () => {
        const status = await doApiCall({
            action: 'api-get-domain-by-hash',
            hash: params.domain
        })

        if(status.data.status === 1){
            setDomain(status.data.data)
        }else{
            setDomain(null)
        }
    }

    const changeContext = async () => {
        if(!params.domain){
            setDomain(null)
        }else{
            if(!domain){
                await loadDomain()
                
            }
            if(domain && domain.hash !== params.domain) await loadDomain()
           
        }
        

        if(!params.company){
            setCompany(null)
        }else{
            if(!company || company.hash !== params.company){
                await loadCompanyByUrl()
                return
            }
            
        }      

        return true
    }
    
    const changePath = (index) => {
        const selectedPath = paths[index]
        
        if(selectedPath){
            setDomain(selectedPath.domain)
            setCompany(selectedPath.company)
            if(location.pathname === '/'){
                navigate(selectedPath.url)
            }
            
        }
    }

    const reloadPaths = async () => {
        const status = await doApiCall({action: 'get-available-paths'})
        setPaths(status.data.paths)

        const test = await doApiCall({action: 'get-available-contexts'})
        
        setContexts(test.data)
    }
    
    const getAvailablePaths = async () => {
        if(!paths){
            const status = await doApiCall({action: 'get-available-paths'})
            setPaths(status.data.paths)

            const test = await doApiCall({action: 'get-available-contexts'})
           
            setContexts(test.data)
        }
    }

    const loadUnreadMessages = async () => {
        const status = await doApiCall({action: 'get-unread-conversation-messages'})
        setUnreadMessages(status.data)
    }

    const loadNotifications = async () => {
        
        const status = await doApiCall({action: 'get-notifications'})
        setNotifications(status.data)
    }

    useEffect(() => {
        
        if(notifications && notifications.length){
            let notifs = []
            let msgs = []
            notifications.map((notification) => {
                
                if(domain && notification.domain){
                    if(notification.domain.id === domain.id){
                        if(notification.company && company){
                            if(notification.company.id === company.id){
                                if(notification.type === 'Notification'){
                                    notifs.push(notification)
                                }else{
                                    msgs.push(notification)
                                }
                                
                            }
                        }else{
                            if(notification.type === 'Notification'){
                                notifs.push(notification)
                            }else{
                                msgs.push(notification)
                            }
                        }
                    }
                }
               
            })
            setContextNotifications(notifs)
            setContextMessages(msgs)
        }else{
            setContextNotifications([])
            setContextMessages([])
        }
    }, [notifications, domain, company])

    const downloadAttachment = async (id, name) => {
        await axios({
            url: apiUrl, //your url
            data: {action: 'download-attachment', id: id, accessToken: accessToken},
            method: 'POST',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    const doApiCall = async (data, files) => {
        
        const formData = new FormData();

        formData.append("accessToken", accessToken);
        data.accessToken = accessToken
        if(domain) data.domain = domain.id
        if(company) data.company = company.id
        

        
        for ( var key in data ) {
            if(key === 'files'){
                for (var file in data[key]){
                    formData.append('files[]', data[key][file])
                }
            }else{
                formData.append(key, data[key]);
            }
            
        }
        
        const status = await axios({
            method: 'post',
            url: apiUrl,
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })

        if(status.data && status.data.status === 5){
            logout()
            return {
                data: {
                    status: 5
                }
            }
        }else{
            return status
        }
    }

    const uploadFile = async (file) => {

        if(!file) return null
        const formData = new FormData();

        formData.append('accessToken', accessToken)
        formData.append('action', 'upload-single-attachment')
        formData.append('files[]', file)

        const { data } = await axios({
            method: 'post',
            url: apiUrl,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })

        if(!data.title && !data.message) notify(data.status, data.title, data.message)
        

        if(data.status !== 1){
            return null
        }

        return data.data

    }

    const setNotificationRead = async (id) => {
        const status = await doApiCall({action: 'set-notification-read', id: id})
        if(status.data.status === 1) loadNotifications()
    }

    const render = () => {
        
        if(!accessToken || !user) return <Login />
        if(!paths) return <FullscreenLoading customText="Laster inn informasjon om området..."/>

        if(domain === null && company === null){
            if(paths.length === 1){
                changePath(0)
                return
            }
            return <PathPicker paths={paths} changePath={changePath} />

        }
        return children

    }

    const logout = () => {
        console.log('Logging out')
        localStorage.removeItem('tudu.access_token')
        localStorage.removeItem('tudu.user_data')
        localStorage.removeItem('tudu.companyStore')
        setUser(null)
        setAccessToken(null)
        setTeams(null)
        setContexts(null)
        setPaths(null)
        setDomain(null)
        setCompany(null)
        setNotifications([])
        setUnreadMessages([])
        setContextNotifications([])        
        setContextMessages([])
        navigate('/')
    }

    const loginSuccess = (user_data, access_token) => {
        localStorage.setItem('tudu.access_token', access_token);
        localStorage.setItem('tudu.user_data', JSON.stringify(user_data));

        setUser(user_data)
        setAccessToken(access_token)
    }

    const getTagableObjects = async () => {
        const { data } = await doApiCall({
            action: 'get-tagable-objects'
        })
        return data
    }

    return <AuthContext.Provider value={{
        accessToken,
        user,
        consultant,
        teamleader,
        admin,
        teams,
        mustRelog,
        notificationCenter,
        messageCenter,
        unreadMessages,
        notifications,
        contextNotifications,
        contextMessages,
        domain,
        company,
        paths,
        contexts,
        uploadFile,
        loadDomain,
        login: async (email, password) => {
            const status = await doApiCall({action: 'api-login', username: email, password: password})
            
            if(status.data.status === 1){
                
                if(mustRelog){
                    notify(1, 'Suksess!', 'Din økt har blitt fornyet 😎')
                }
                setMustRelog(false)
                localStorage.setItem('tudu.access_token', status.data.access_token);
                localStorage.setItem('tudu.user_data', JSON.stringify(status.data.user_data));
                setUser(status.data.user_data)
                setAccessToken(status.data.access_token)
                setConsultant(status.data.consultant)
                setTeams(status.data.teams)
            }else{
                notify(status.data.status, status.data.title, status.data.message)
            }

            return status

        },
        logout,
        getLoginState: async () => {
            const status = await doApiCall({action: 'api-get-user'})
            
            if(status.data.status === 1){
                
                if(mustRelog) notify(1, 'Suksess!', 'Din økt har blitt fornyet 😎')

                setMustRelog(false)
                localStorage.setItem('tudu.user_data', JSON.stringify(status.data.data.user));
                setAccessToken(accessToken)
                setUser(status.data.data.user)
                setConsultant(status.data.data.consultant)
                setTeams(status.data.data.teams)
            }
            
        },
        apiCall: async (data) => {
            const status = await doApiCall(data)
            return status            
        },
        toggleNotificationCenter: (bool) => {
            setNotificationCenter(bool)
        },
        toggleMessageCenter: (bool) => {
            setMessageCenter(bool)
        },
        getUnreadMessages: () => {
            loadUnreadMessages()
        },
        getNotifications: () => {
            loadNotifications()
        },
        changeDomain: (domain) => {
            
        },
        changeCompany: (company) => {
            
        },
        getAvailablePaths,
        reloadPaths,
        downloadAttachment,
        loadCompanyByUrl,
        setNotificationRead,
        reloadUser,
        loginSuccess,
        getTagableObjects
    }}>
        {
            render()
        }
    </AuthContext.Provider>
}

function Loading(){
    return (
        <h1>Laster</h1>
    )
}

function PathPicker({ paths, changePath }){
    
    const { logout, contexts } = useContext(AuthContext)

    const navigate = useNavigate();

    const [visibleContexts, setVisibleContexts] = useState(contexts)
    const [search, setSearch] = useState('')

    useEffect(() => {
        setVisibleContexts(contexts)
    }, [contexts])

    useEffect(() => {
        if(contexts && contexts.length){
            setVisibleContexts(contexts.filter(domain => {
                //Returnerer true hvis navnet på domenet matcher
                if(domain.name.match(new RegExp(search, 'i'))) return true
                return domain.companies.filter(company => company.name.match(new RegExp(search, 'i'))).length
            
            }))
        }
    }, [search])

    const DomainItem = (props) => {
        const { name, config, hash, companies } = props
        return (
            <div>
                <div className="p-3 mb-2 pointer border-bottom" onClick={() => navigate(`/${hash}/oversikt`)}>
                    <div className="row gx-5 align-items-center">
                        <div className="col">
                            <h5 className="bold m-0">{name}</h5>
                        </div>
                        <div className="col-auto">
                            <UserPhoto photo={config.logoSmall} name={name} size={[50, 50 ,50]} />
                        </div>
                    </div>
                </div>
                {
                    companies.length ? companies.map((company, key) => {
                        return <CompanyItem key={key} {...company} domain={props} />
                    }) : null
                }
            </div>       
        )
    }

    const CompanyItem = (props) => {
        const { name, hash, image, domain } = props
        return (
            <div className="p-3 mb-2 pointer border-bottom" onClick={() => navigate(`/${domain.hash}/${hash}/oversikt`)}>
                <div className="row gx-5 align-items-center">
                    <div className="col">
                        <h5 className="bold m-0">{name}</h5>
                        <h6 className="hint-text m-0">{domain.name}</h6>
                    </div>
                    <div className="col-auto">
                        <UserPhoto photo={image} name={name} size={[50, 50 ,50]} />
                    </div>

                </div>
            </div>       
        )
    }
    
    const BaseItem = (props) => {
        const { contextMember, companies } = props
        if(contextMember) return <DomainItem {...props} />

        if(companies.length){
            return companies.map((company, key) => {
                return <CompanyItem key={key} {...company} domain={props} />
            })
        }

        return <div></div>
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center p-md-0 p-3 pb-5" style={{height: '100vh'}}>
            <div className="box p-md-4 p-3 px-md-4" style={{maxWidth: '740px'}}>
                
                <h2 className="text-center bold">Velg område</h2>
                <p className="text-center">Du er medlem av flere områder. Vennligst velg området du vil jobbe åpne. Dette kan du enkelt bytte etter når du er inne i systemet.</p>
                <div className="filter-areas px-2 my-3">
                    <div className="position-relative form-group">
                        <FontAwesomeIcon icon={['far', 'magnifying-glass']} className="position-absolute pe-none" style={{top: '50%', left: '1rem', transform: 'translateY(-50%)', color: '#6c757d'}} />
                        <input className="w-100 form-control ps-5" value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Søk i områder"></input>
                    </div>
                    
                </div>
                <div className="small-scroll px-2" style={{maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden'}}>
                    {visibleContexts && visibleContexts.length 
                        ? 
                            visibleContexts.map((item, key) => <BaseItem key={key} index={key} {...item} />) 
                        : 
                            contexts && contexts.length 
                            ? 
                                <div className="text-center">
                                    <p>Ingen resultater matchet ditt søk.</p>
                                </div>
                            :
                                <div className="text-center">
                                    <p>Vi finner ingen områder tilknyttet din bruker. Vennligst kontakt IT-ansvarlig.</p>
                                </div>
                            
                    }
                </div>
            </div>
            <div className="py-3">
                <button className="btn btn-link" onClick={() => logout()}>Logg ut</button>
            </div>
        </div>
    )
}