/* eslint-disable */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AuthContext } from "../providers/AuthProvider";
import { useOnClickOutside } from './Functions';
import Modal from './Modal';
import UserPhoto from "./UserPhoto";
import VersionNews from './VersionNews';
import data from '../versions.json'
import moment from 'moment';
import { ConfigContext } from '../providers/ConfigProvider';
import CompanyPicker from './CompanyPicker';
import ContextPicker from './ContextPicker';
import { DomainContext } from '../providers/DomainProvider';

export default function Header(){
    const { user, toggleNotificationCenter, toggleMessageCenter, logout, contextNotifications, contextMessages, company, domain} = useContext(AuthContext)
    const { toggleNav, screenSize, dev } = useContext(ConfigContext)
    const { domainPermissions } = useContext(DomainContext)
    const [toggleMenu, setToggleMenu] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)

    const [versionBadge, setVersionBadge] = useState(null)
    const [notificationBadge, setNotificationBadge] = useState(null)
    const [messageBadge, setMessageBadge] = useState(null)
    
    

    const getVersionBadge = () => {
        const lastSeenVersionDate = localStorage.getItem('tudu.lastSeenVersion')
        if(!lastSeenVersionDate) {
            setVersionBadge(data.length)
            return
        }

        const lastVersion = moment(lastSeenVersionDate)
        let number = 0
        data.forEach((item) => {
            let date = moment(item.date)
            if(!lastVersion.isSameOrAfter(date)) number++;
        })
        setVersionBadge(number)
    }

    const getNotificationBadge = () => {
        setNotificationBadge(contextNotifications.filter(n => !n.isRead).length)
    }

    const getMessageBadge = () => {
        setMessageBadge(contextMessages.filter(n => !n.isRead).length)
    }

    useEffect(() => {
        window.addEventListener("itemInserted",(e) => getVersionBadge, false);
        getVersionBadge()
    }, [])

    useEffect(() => {
        getNotificationBadge()
    }, [contextNotifications])
    
    useEffect(() => {
        getMessageBadge()
    }, [contextMessages])

    const ref = useRef()
    useOnClickOutside(ref, () => setToggleMenu(false))


    return (
        <div className="header bg-white">
            <div className="row justify-content-end gx-0">
                
                <div className="row justify-content-between gx-0">
                   
                    <div className="col-auto row">
                        <div className="border-right  d-block d-md-none ">
                            <div className="px-md-4 px-2 h-100 d-flex align-items-center">
                                <button className="btn btn-link" onClick={() => toggleNav() }>                                   
                                    <FontAwesomeIcon className="text-grey" icon={['far', 'bars']} size={'lg'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <ContextPicker />
                    </div>
                    <div className="col row justify-content-end align-items-center gx-0">
                        
                        <button className="btn-header" onClick={() => setToggleModal(true) }>
                            {versionBadge ? <span className='notification-badge' style={{transform: 'translate(15px, -8px)'}}>{versionBadge}</span> : null}
                            <FontAwesomeIcon icon={['far', 'heart-pulse']} size={'lg'}/>
                        </button>
                        <button className="btn-header" onClick={() => toggleMessageCenter(true) }>
                            {messageBadge ? <span className='notification-badge' style={{transform: 'translate(15px, -8px)'}}>{messageBadge}</span> : null}
                            <FontAwesomeIcon icon={['far', 'message']} size={'lg'}/>
                        </button>
                        <button className="btn-header" onClick={() => toggleNotificationCenter(true) }>
                            {notificationBadge ? <span className='notification-badge' style={{transform: 'translate(15px, -8px)'}}>{notificationBadge}</span> : null}
                            <FontAwesomeIcon  icon={['far', 'bell']} size={'lg'}/>
                        </button>
                        <div className="col-auto border-left ">
                            <div className="py-2 px-md-4 px-3 pointer" onClick={() => setToggleMenu(true)}>
                                <div className="row gx-md-3 gx-1 align-items-center">
                                    <div className="col-auto">
                                        <UserPhoto photo={user.photo} name={user.fullname} size={[50, 40, 30]} />
                                    </div>
                                    <div className="col-auto">
                                        <div className="d-none d-md-block">
                                            <h6 className="m-0">{user.fullname}</h6>
                                            <small className="hint-text">{user.role}</small>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <FontAwesomeIcon size="sm" icon={['far', 'chevron-down']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                
            </div>
            <CSSTransition in={toggleMenu} timeout={250} classNames="notification-center-wrapper" unmountOnExit>
                <div className="profile-menu" ref={ref}>
                    <div className="box">
                        <div className="p-3 border-bottom">
                            <div className="row gx-2 align-items-center">
                                <div className="col-auto">
                                    <UserPhoto photo={user.photo} name={user.fullname} size={[40, 30, 30]} />
                                </div>
                                <div className="col">
                                    <div className="" style={{lineHeight: 1}}>
                                        <small className="d-block bold">{user.fullname}</small>
                                        <small className="hint-text">{user.email}</small>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon className='pointer' onClick={() => setToggleMenu(false)} icon={['fas', 'xmark']} />
                                </div>
                            </div>
                        </div>
                        <div className="profile-menu-list border-bottom">
                            <Link to={company ? `/${domain.hash}/${company.hash}/profil/rediger` : `/${domain.hash}/profil/rediger`} className='profile-menu-list-item hover-primary'>
                                <div className="profile-menu-list-icon">
                                    <FontAwesomeIcon icon={['far', 'pen']} />
                                </div>
                                <h6 className="m-0">Rediger profilinformasjon</h6>
                            </Link>
                            <Link to={company ? `/${domain.hash}/${company.hash}/profil/endre-passord` : `/${domain.hash}/profil/endre-passord`} className='profile-menu-list-item hover-primary'>
                                <div className="profile-menu-list-icon">
                                    <FontAwesomeIcon icon={['far', 'lock']} />
                                </div>
                                <h6 className="m-0">Endre passord</h6>
                            </Link>
                        </div>
                        
                        <button onClick={() => logout()} className='p-3 py-2 btn btn-link w-100 text-left hover-primary'>
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <h6 className="bold">Logg ut</h6>
                                </div>
                                <div className="col-auto">
                                    <FontAwesomeIcon icon={['far', 'power-off']} />
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </CSSTransition>
            <Modal
                open={toggleModal}
                setOpen={setToggleModal}
            >
                <VersionNews />
            </Modal>
        </div>
    )
}